import http from '@/common/js/http'

/**
 * 添加转账记录
 */
export const addTransferAccountsApi = async (data) => {
    return http('/galaxyTransferAccounts/addTransferAccounts', data, 'POST')
}

/**
 * 出纳审核
 */
export const addTransferAccountsCashierAuditApi = async (data) => {
    return http('/galaxyTransferAccounts/addTransferAccountsCashierAudit', data, 'POST')
}

/**
 * 业务初审核
 */
export const addTransferAccountsCashierReviewApi = async (data) => {
    return http('/galaxyTransferAccounts/addTransferAccountsCashierReview', data, 'POST')
}

/**
 * 初审拒绝
 */
export const addTransferAccountsCashierReviewRefuseApi = async (data) => {
    return http('/galaxyTransferAccounts/addTransferAccountsCashierReviewRefuse', data, 'POST')
}

/**
 * 财务审核
 */
export const addTransferAccountsFinanceAuditApi = async (data) => {
    return http('/galaxyTransferAccounts/addTransferAccountsFinanceAudit', data, 'POST')
}

/**
 * 财务拒绝
 */
export const addTransferAccountsFinanceRefuseApi = async (data) => {
    return http('/galaxyTransferAccounts/addTransferAccountsFinanceRefuse', data, 'POST')
}


/**
 * 记录列表
 */
export const getTransferAccountsInfoListApi = async (data) => {
    return http('/galaxyTransferAccounts/getTransferAccountsInfoList', data, 'POST')
}

/**
 * 获取记录详情
 */
export const getTransferAccountsInfoByIdApi = async (data) => {
    return http('/galaxyTransferAccounts/getTransferAccountsInfoById', data, 'POST')
}
