<style lang="scss" scoped>
    .product-list {
        .title{
            height:25px;
            font-size:18px;
            font-weight:700;
            color:rgba(51,51,51,1);
            line-height:25px;
        }

        .product-check-list {
            margin-top: 5px;

            .check-list-title {
                font-size: 14px;
                margin-right: 20px;
            }

            .check-list-group {
                display: inline-block;
            }
        }

    }
</style>
<style lang="scss">

    .product-list-table {
        margin-top: 10px;
        .operation-btn {
            .el-button {
                padding: 0;
                margin: 5px;
                margin-left: 0;
                float: left;
            }
        }
    }

    .common-list-search .list-search-before {
        .label-pub-w {
            &.el-form-item.el-form-item--small {
                width: auto;
                /*min-width: 320px;*/
            }

            .el-form-item__content {
                width: auto;
            }
        }
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="product-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <p class="title">门市充值详情</p>
                        <p style="line-height: 54px">
                            <span>旅行社名称: {{agencyInfo.merchantName}} </span>
                            <span style="padding-left: 80px">机构ID:    {{agencyInfo.merchantId}}</span>
                            <span style="padding-left: 80px">充值次数:    {{agencyInfo.merchantName||0}}次</span>
                            <span style="padding-left: 80px">账户目前余额:    {{agencyInfo.merchantName||0}}元</span></p>
                    </div>

                </com-list-search>
                <div class="product-list-table el-table_border_none">
                    <el-table
                            class="table-info"
                            :data="rechargeList"
                            v-loading="listLoading"
                            style="width: 100%"
                    >
                        <el-table-column type="index" :index="indexMethod" label="序号" width="110"></el-table-column>
                        <el-table-column width="200" label="充值申请时间">
                            <template slot-scope="scope">
                                <span>{{scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rechargeAmount" label="充值金额" min-width="120"></el-table-column>
                        <el-table-column prop="proposerName" label="充值人姓名" min-width="120"></el-table-column>
                        <el-table-column prop="proposerMobile" label="手机号" min-width="160">
                        </el-table-column>
                        <el-table-column label="银行汇款回执单" min-width="80">
                            <template slot-scope="scope">
                                <el-image lazy
                                          style="width: 60px;height: 35px;"
                                          :src="scope.row.receiptPhotoUrl"
                                          :preview-src-list="[scope.row.receiptPhotoUrl]">
                                </el-image>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="pageChange"
                            :current-page.sync="pageData.currentPage"
                            :page-size="pageData.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
    </div>
</template>

<script type="text/ecmascript-6">
    import comListSearch from "../../../components/comListSearch/index.vue";
    import SectionContent from "@/components/sectionContent/index.vue";

    export default {
        //定义模版数据
        name: "rechargeDetail",
        data() {
            return {
                form: {},
                pageData: {
                    // 列表分页
                    pageSize: 10, // 每页显示条目个数
                    totalPage: 1, // 总条目个数
                    currentPage: 1 // 当前页数
                },
                rechargeList: [],
                listLoading: false,
                dialogTableVisible: false,
                agencyInfo: {}
            };
        },
        components: {
            SectionContent,
            comListSearch
        },
        //计算属性
        computed: {
        },
        //主件被加载完成
        mounted: function () {
            this.searchClick();
        },
        //定义事件方法
        methods: {
            resetClick() {
                // 重置
                this.form = {
                };
                this.searchClick();
            },
            pageChange(val) {
                this.pageData.currentPage = val;
                this.searchClick();
            },
            async searchClick() {
                let form = {
                    createOrginfoId: this.$route.params.id,
                    pageIndex: this.pageData.currentPage,
                    pageSize: this.pageData.pageSize
                }
                let res = await this.http('/galaxyAccountAuditInfo/getAccountAuditInfoListByOrginfoId', form, 'POST');
                console.log(res);
                if(res.success){
                    this.rechargeList = res.data.accountAuditInfoDtoPageList.data;
                    this.pageData.totalPage = res.data.accountAuditInfoDtoPageList.total;
                    this.agencyInfo = res.data.merchantInfoPageRspDto
                }
            },
            indexMethod(index) {
                return (index+1)+ (this.pageData.currentPage-1)*10;
            },

        },
        //监听模版变量
        watch: {}
    };
</script>

