<template>
    <div class="code-input">
        <el-input class="input" v-bind="$attrs" v-for="vm in count"
                  :autofocus="vm===1"
                  :key="vm" ref="inputRef" v-model="code[vm]" :maxlength="1"
                  :data-index="vm" @input="handleInput(vm)" @keyup.native.delete="handleDelete(vm)"></el-input>
    </div>
</template>

<script>
export default {
    name: "CodeInput",
    props: {
        count: {
            type: Number,
            default: 6
        }
    },
    data() {
        return {
            code: []
        }
    },
    methods: {
        handleInput(index) {
            if (index < this.count && this.code[index]) {
                this.$refs[`inputRef`][index].focus();
            }
            this.$emit('change', this.code.join(''));
        },
        handleDelete(index) {
            if (index > 1) {
                this.$refs[`inputRef`][index - 2].focus();
            }
            this.$emit('change', this.code.join(''));
        },
        setValue(code = '') {
            this.code = code.split('');
        },
        setFocus(index = 0) {
            this.$refs['inputRef'][index].focus()
        }
    }
}
</script>

<style scoped lang="scss">
.code-input {
    display: flex;

    .input {
        width: 40px;
        height: 40px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
</style>
