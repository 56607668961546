import http from '@/common/js/http'

/**
 * 获取用户支付密码
 */
export const getUserPayPwdApi = async (data) => {
    return http('/galaxyLogin/getUserPayPwd', data, 'POST')
}

/**
 * 设置用户支付密码
 */
export const addUserPayPwdApi = async (data) => {
    return http('/galaxyLogin/addUserPayPwd', data, 'POST')
}

/**
 * 更新用户支付密码
 */
export const updateUserPayPwdApi = async (data) => {
    return http('/galaxyLogin/updateUserPayPwd', data, 'POST')
}
