<template>
    <el-dialog :visible.sync="dialogVisible" width="500px">
        <!--密码设置成功-->
        <div class="section">
            <h3 class="title">忘记密码</h3>
            <p class="content">第1步：发送邮件给管理人员，申请重置密码<br/>
                第2步：经管理人员批准后，由技术管理员重置密码</p>
            <p class="tips">重置密码是指将原密码在系统中抹除，重置后您可重新设置安全密码</p>
        </div>
        <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible=false">
                  好的
                </el-button>
  </span>
    </el-dialog>
</template>

<script>

export default {
    name: "ForgetPasswordDialog",
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
        },
    }
}
</script>

<style scoped lang="scss">
.section {
    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
    }

    .tips {
        margin-bottom: 10px;
    }

    .content {
        font-size: 15px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
    }

    .buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 36px;
    }
}
</style>
