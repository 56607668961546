<template>
    <div class="transfer-accounts-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="transfer-accounts-content" ref="printContent" id="printView">
                    <div class="section">
                        <div class="head">
                            <img src="@/assets/images/logo_full.png" class="logo" alt="">
                            <p class="title">转账详情</p>
                        </div>
                        <div style="padding-bottom: 100px">
                            <el-descriptions class="mt15" :column="1" :colon="false"
                                             :label-style="labelStyle"
                                             :content-style="contentStyle">
                                <el-descriptions-item label="状态：">
                                    <b>
                                        {{ form.status | formatDesc(statusOptions) }}
                                    </b>
                                    <el-popover
                                        v-if="form.status===3"
                                        placement="top-start"
                                        width="200"
                                        trigger="hover">
                                        <p class="color-error">{{ form.remarkInfo }}</p>
                                        <i slot="reference" style="cursor: pointer"
                                           class="icon icon-not-pass color-error"></i>
                                    </el-popover>
                                    （申请人：{{
                                        form.craeteLoginName
                                    }}{{
                                        form.reviewLoginName ? ' - 业务审核人：' + form.reviewLoginName : ''
                                    }}{{
                                        form.transferAccountsLoginName ? ' - 财务审核人：' + form.transferAccountsLoginName : ''
                                    }}{{ form.confirmLoginName ? ' - 转出人：' + form.confirmLoginName : '' }}）
                                </el-descriptions-item>
                                <el-descriptions-item label="申请时间：">
                                    {{ form.createTime | dateCus }}
                                </el-descriptions-item>
                                <el-descriptions-item label="转出时间：" v-if="form.confirmTime">
                                    {{ form.confirmTime | dateCus }}
                                </el-descriptions-item>
                                <el-descriptions-item label="转账流水信息：" v-if="[2,4,5,6].includes(form.status)">
                                    详见 流水信息表
                                </el-descriptions-item>
                                <el-descriptions-item label="支付主体：">
                                    {{ form.accountId }}
                                </el-descriptions-item>
                                <el-descriptions-item label="支付主体名称：">
                                    {{ form.accountName }}
                                </el-descriptions-item>
                                <el-descriptions-item label="收款单位：">
                                    <div class="alipay-box">
                                        <img class="icon-alipay" src="@/assets/images/alipay.png" alt="">
                                        {{ form.sendName }}
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item label="收款账号：">
                                    <div class="alipay-box">
                                        <img class="icon-alipay" src="@/assets/images/alipay.png" alt="">
                                        {{ form.sendId }}
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item label="转账金额：">
                                    {{ form.transferAccountsPrice | currency(1,'¥') }}
                                    人民币{{ form.transferAccountsPrice | chineseCurrency }}
                                </el-descriptions-item>
                                <el-descriptions-item label="期望支付日期：">
                                    {{ form.needSendTime | dateCus('yyyy-MM-dd') }}
                                </el-descriptions-item>
                                <el-descriptions-item label="凭证：">
                                    <div v-if="form.voucherPhoto">
                                        <el-image
                                            fit="contain"
                                            :src="form.voucherPhoto"
                                            :preview-src-list="[form.voucherPhoto]">
                                        </el-image>
                                    </div>
                                    <p v-else>无</p>
                                </el-descriptions-item>
                                <el-descriptions-item label="备注：">
                                    {{ form.remarkInfo || '无' }}
                                </el-descriptions-item>
                            </el-descriptions>
                        </div>
                    </div>
                    <div class="section" style="page-break-before:always">
                        <div class="head">
                            <img src="@/assets/images/logo_full.png" class="logo" alt="">
                            <p class="title">转账详情</p>
                        </div>
                        <FlowTable :info="form"></FlowTable>
                    </div>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="buttons">
                <el-button type="primary" style="width: 110px" plain :loading="loading" v-print="printObj">
                    打印
                </el-button>
            </div>
        </el-footer>
    </div>
</template>
<script>
import {
    getTransferAccountsInfoByIdApi
} from "@/www/urls/transferAccounts";
import {statusOptions} from "@/data/transferAccounts";
import FlowTable from "@/www/pages/financeManage/transferAccounts/FlowTable";

export default {
    components: {
        FlowTable
    },
    data() {
        return {
            statusOptions,
            loading: false,
            form: {},
            labelStyle: {
                width: '130px',
                textAlign: 'right',
                display: 'inline-block',
                fontWeight: 'normal',
                color: '#333'
            },
            contentStyle: {
                fontWeight: 'normal',
                color: '#333'
            },
            printObj: {
                id: 'printView',
                popTitle: '转账详情'
            },
        }
    },
    mounted() {
        this.form = JSON.parse(decodeURIComponent(this.$route.query.info));
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.form = (await getTransferAccountsInfoByIdApi({
                    id: this.form.id
                })).data
            } finally {
                console.log(3333);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.transfer-accounts-detail {
    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
    }
}

.transfer-accounts-content {
    margin-bottom: 50px;
    width: 800px;

    .section {
        padding: 0 20px;
        margin-top: 10px;
        background-color: #fff;
    }

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
        position: relative;
        margin-bottom: 30px;
        border-bottom: 1px solid #E3E3E3;

        .logo {
            width: 80px;
        }

        .title {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .alipay-box {
        display: flex;
        align-items: center;
        position: relative;

        .icon-alipay {
            width: 52px;
            height: 20px;
            margin-right: 20px;
        }

        &:after {
            content: ' ';
            position: absolute;
            left: 62px;
            width: 1px;
            height: 14px;
            background-color: #909399;
        }
    }

    &::v-deep() {
        .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
            padding-bottom: 25px;
        }

        .el-alert--warning {
            background: #E6F7FF;
            border: 1px solid #BAE7FF;
            border-radius: 4px;
            height: 40px;
        }

        .image-cont {
            margin-left: 0;
        }

        .el-icon-warning {
            color: #1890FF;
        }

        .el-alert__title {
            color: rgba(0, 0, 0, .65);
            font-size: 14px;
        }

        img {
            max-width: 210px;
            max-height: 250px;
        }
    }
}

</style>
