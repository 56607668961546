<style lang="scss" scoped>
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="audit-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="form">
                            <div>
                                <el-form-item>
                                    <el-radio-group v-model="form.auditStatus">
                                        <el-radio-button :label="item.value" v-for="item in auditStatus" :key="item.value">{{item.name}}{{item.num}}</el-radio-button>
                                        <!--<el-radio-button :label="0">待审核</el-radio-button>-->
                                        <!--<el-radio-button :label="1">已通过</el-radio-button>-->
                                        <!--<el-radio-button :label="2">未通过</el-radio-button>-->
                                    </el-radio-group>
                                </el-form-item>
                            </div>

                            <el-form-item>
                                <el-input placeholder="旅行社名称" v-model="form.companyName"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="手机号" v-model="form.proposerMobile"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="机构ID" v-model="form.merchantId"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-date-picker
                                        v-model="form.createTime"
                                        type="daterange" value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="申请充值起始日期"
                                        end-placeholder="申请充值截止日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="resetClick()">重置</el-button>
                        <el-button type="primary" @click="searchClick()">搜索</el-button>
                    </div>
                </com-list-search>
                <div class="product-list-table el-table_border_none">
                    <el-table
                            class="table-info"
                            border
                            :data="auditList"
                            v-loading="listLoading"
                            style="width: 100%">
                        <el-table-column type="index" :index="indexMethod" prop="driverName" label="序号"
                                         width="110"></el-table-column>
                        <el-table-column prop="merchantId" width="120" label="机构ID"></el-table-column>
                        <el-table-column prop="companyName" label="旅行社名称" width="180"></el-table-column>
                        <el-table-column prop="proposerName" label="联系人姓名" width="120"></el-table-column>
                        <el-table-column prop="proposerMobile" label="手机号" width="120"></el-table-column>
                        <el-table-column label="申请充值时间" min-width="180">
                            <template slot-scope="scope">
                                <span>{{scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rechargeAmount" label="充值金额" min-width="80"></el-table-column>
                        <el-table-column label="银行汇款回执单" min-width="80">
                            <template slot-scope="scope">
                                <el-image lazy
                                          style="width: 60px;height: 35px;"
                                          :src="scope.row.receiptPhotoUrl"
                                          :preview-src-list="[scope.row.receiptPhotoUrl]">
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.auditStatus===0">待审核</span>
                                <span v-if="scope.row.auditStatus===1">已通过</span>
                                <div v-if="scope.row.auditStatus===2">
                                    <el-tooltip placement="top" effect="light">
                                        <div slot="content">
                                            <div style="width: 180px;min-height: 40px;max-height105px;color: #ff3b30">
                                                {{scope.row.rejectInfo}}
                                            </div>
                                        </div>
                                        <el-button type="text" style="color: #ff3b30">未通过</el-button>
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150">
                            <template slot-scope="scope">
                                <div v-if="scope.row.auditStatus===0">
                                    <el-button type="text" @click="showDialog(scope.row, 1)">通过</el-button>
                                    <el-button type="text" @click="showDialog(scope.row, 2)">拒绝</el-button>
                                </div>

                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="pageChange"
                            :current-page.sync="pageData.currentPage"
                            :page-size="pageData.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
        <el-dialog :visible.sync="dialogTableVisible" width="433px">
            <div slot="title">
                <i class="el-icon-warning" style="color: rgba(250,173,20,1);font-size:16px;"></i>
                <span style="font-size:16px;font-weight:500;color:rgba(0,0,0,0.85);">通过审核</span>
            </div>
            <p style="font-size: 14px;color:rgba(0,0,0,0.65);line-height:22px;">此操作不可逆，请确认内容已被仔细审阅，没有遗漏。</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="rejectDialog" width="433px">
            <div slot="title">
                <i class="el-icon-warning" style="color: rgba(250,173,20,1);font-size:16px;"></i>
                <span style="font-size:16px;font-weight:500;color:rgba(0,0,0,0.85);">不通过审核</span>
            </div>
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item prop="rejectInfo">
                        <el-input
                                type="textarea"
                                :autosize="{ minRows: 4, maxRows: 6}"
                                placeholder="请输入未通过原因…"
                                v-model="ruleForm.rejectInfo">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="rejectDialog = false">取 消</el-button>
                <el-button type="primary" @click="refuse()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
    import comListSearch from "../../../components/comListSearch/index.vue";
    import SectionContent from "@/components/sectionContent/index.vue";

    export default {
        //定义模版数据
        name: "rechargeAudit",
        data() {
            return {
                form: {
                    auditStatus: 99
                },
                pageData: {
                    // 列表分页
                    pageSize: 10, // 每页显示条目个数
                    totalPage: 1, // 总条目个数
                    currentPage: 1 // 当前页数
                },
                auditList: [],
                listLoading: false,
                dialogTableVisible: false,
                rejectDialog: false,
                ruleForm: {},
                rules: {
                    rejectInfo: [
                        { required: true, message: '请填写拒绝理由', trigger: 'blur' }
                    ]
                },
                id: '',
                auditStatus: [
                    {name: '全部', value: 99},
                    {name: '待审核', value: 0, num:0},
                    {name: '已通过', value: 1, num:0},
                    {name: '未通过', value: 2}
                ]
            };
        },
        components: {
            SectionContent,
            comListSearch
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.searchClick();
            this.getStatusList();
        },
        //定义事件方法
        methods: {
            resetClick() {
                // 重置
                this.form = {};
                this.searchClick();
            },
            pageChange(val) {
                this.pageData.currentPage = val;
                this.searchClick();
            },
            async searchClick() {
                let form = {
                    companyName: this.form.companyName,
                    auditStatus: this.form.auditStatus,
                    proposerMobile: this.form.proposerMobile,
                    merchantId: this.form.merchantId
                };
                if(form.auditStatus===99) delete form.auditStatus;
                if(this.form.createTime) {
                    form.createStartTime = +new Date(this.form.createTime[0]);
                    form.createEndTime = +new Date(this.form.createTime[1] + ' 23:59:59');
                }
                form.pageIndex = this.pageData.currentPage;
                form.pageSize = this.pageData.pageSize;
                let res = await this.http('/galaxyAccountAuditInfo/getAccountAuditInfoList', form, 'POST');
                console.log(res);
                if (res.success) {
                    this.auditList = res.data.data;
                    this.pageData.totalPage = res.data.total;
                }
            },
            indexMethod(index) {
                return (index + 1) + (this.pageData.currentPage - 1) * 10;
            },
            async confirm(){
                this.loadingShow();
                let form = {
                    auditStatus: 1,
                    id: this.id

                }
                const res = await this.http("/galaxyAccountAuditInfo/confirm", form, 'POST');
                this.loadingHide();
                if(res.success){
                    this.$message.success('操作成功');
                    this.dialogTableVisible = false;
                    this.searchClick();
                }else this.messageError('网络错误,请稍后重试')
            },
            refuse(){
                this.$refs.ruleForm.validate(async(valid) => {
                    if(valid) {
                        this.loadingShow();
                        let form = {
                            auditStatus: 2,
                            id: this.id,
                            rejectInfo: this.ruleForm.rejectInfo
                        }
                        const res = await this.http("/galaxyAccountAuditInfo/reject", form, 'POST');
                        this.loadingHide();
                        if(res.success){
                            this.$message.success('操作成功');
                            this.rejectDialog = false;
                            this.searchClick();
                        }else this.messageError('网络错误,请稍后重试')
                    }
                })
            },
            showDialog(item, type){
                if(type===1) this.dialogTableVisible = true;
                else  this.rejectDialog = true;
                this.id = item.id
            },
            async getStatusList(){
                const res = await this.http('/galaxyAccountAuditInfo/getAccountAuditStatusCount', '', 'POST');
                console.log(res);
                if(res.success){
                    // this.statusCount = res.data;
                    this.auditStatus.forEach(item=>{
                        res.data.forEach(item1=>{
                            if(item.value===item1.audit_status) item.num = item1.num;
                        })
                    })
                }
            }
        },
        //监听模版变量
        watch: {}
    };
</script>

