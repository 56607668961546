<template>
    <div class="transfer-accounts-password">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="section section1" v-if="[-1,0].includes(step)">
                    <h3 class="title">请在此设置安全密码</h3>
                    <p class="content">
                        安全密码将用于在转账/支付时的确认操作<br/>
                        请设置好后妥善保管密码，切勿外泄
                    </p>
                    <el-button type="primary" class="mt40" :disabled="step===-1" @click="handleSetPassword">
                        初次设置安全密码
                    </el-button>
                </div>
                <!--已有安全密码-->
                <div class="section section4" v-if="step===1">
                    <h3 class="title">请设置好后妥善保管密码，切勿外泄</h3>
                    <p class="tips">
                        安全密码将用于在转账/支付时的确认操作<br/>
                        请设置好后妥善保管密码，切勿外泄</p>
                    <div class="buttons">
                        <el-button type="primary" @click="handleUpdatePassword">
                            修改密码
                        </el-button>
                        <el-button type="primary" plain @click="handleForgetPassword">
                            忘记密码
                        </el-button>
                    </div>
                </div>
            </div>
        </el-main>
        <SetPasswordDialog ref="setPasswordDialogRef" @onRefresh="checkPasswordStatus"></SetPasswordDialog>
        <UpdatePasswordDialog ref="updatePasswordDialogRef"></UpdatePasswordDialog>
        <ForgetPasswordDialog ref="forgetPasswordDialog"></ForgetPasswordDialog>
    </div>

</template>

<script>
import SetPasswordDialog from "@/www/pages/financeManage/transferAccounts/components/SetPasswordDialog";
import UpdatePasswordDialog from "@/www/pages/financeManage/transferAccounts/components/UpdatePasswordDialog";
import ForgetPasswordDialog from "@/www/pages/financeManage/transferAccounts/components/ForgetPasswordDialog";
import {getUserPayPwdApi} from "@/www/urls/user";

export default {
    name: 'transferAccountsPassword',
    components: {
        SetPasswordDialog,
        UpdatePasswordDialog,
        ForgetPasswordDialog
    },
    data() {
        return {
            step: -1//0没有密码  4已有安全密码
        }
    },
    activated() {
        this.checkPasswordStatus();
    },
    methods: {
        async checkPasswordStatus() {
            try {
                this.loadingShow();
                const ret = await getUserPayPwdApi()
                if (ret.success) {
                    this.step = ret.data ? 1 : 0;
                } else
                    this.$message.error(ret.errors[0].message)
            } finally {
                this.loadingHide();
            }
        },
        handleSetPassword() {
            this.$refs['setPasswordDialogRef'].open();
        },
        handleUpdatePassword() {
            this.$refs['updatePasswordDialogRef'].open();
        },
        handleForgetPassword() {
            this.$refs['forgetPasswordDialog'].open()
        }
    }
}
</script>

<style scoped lang="scss">
.transfer-accounts-password {
    .eTrip-section-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .section {
            .title {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .tips {
                margin-bottom: 10px;
            }

            .buttons {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                margin-top: 36px;
            }
        }

        .section1, .section4 {
            text-align: center;

            .title {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .content {
                font-size: 16px;
            }
        }
    }
}
</style>
