<template>
    <div class="transfer-accounts-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="transfer-accounts-content">
                    <el-form ref="form" size="medium" class="form" label-width="130px" :model="form" :rules="rules">
                        <el-form-item label-width="0">
                            <el-alert size="small"
                                      title="向支付宝账户转账流程：申请人提交申请 → 审核人审核通过 → 转出人操作转账"
                                      type="warning"
                                      :closable="false" show-icon>
                            </el-alert>
                        </el-form-item>
                        <el-form-item label=" 支付主体：" prop="accountId">
                            <ApiSelect style="width:400px " :options="paymentSubjectOptions" placeholder="支付主体"
                                       v-model="form.accountId"></ApiSelect>
                        </el-form-item>
                        <el-form-item label="收款单位：" prop="sendName">
                            <el-autocomplete style="width: 400px;" v-model="form.sendName"
                                             :fetch-suggestions="querySearch"
                                             placeholder="输入收款单位的支付宝用户名"
                                             clearable
                                             @select="handleSendNameSelect">
                                <img class="icon-alipay" src="@/assets/images/alipay.png" slot="prepend" alt="">
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item label="收款账号：" prop="sendId">
                            <el-input style="width: 400px;"
                                      placeholder="输入收款单位的支付宝账号"
                                      v-model="form.sendId" clearable>
                                <img class="icon-alipay" src="@/assets/images/alipay.png" slot="prepend" alt="">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="转账金额：" prop="transferAccountsPrice">
                            <c-input style="width: 400px;" type="number"
                                     placeholder="输入金额"
                                     v-model="form.transferAccountsPrice" clearable>
                                <p style="width: 20px;text-align: center;color: #333" slot="prefix">¥</p>
                            </c-input>
                            <p v-if="form.transferAccountsPrice">
                                人民币{{ form.transferAccountsPrice | chineseCurrency }}
                            </p>
                        </el-form-item>
                        <el-form-item label="期望支付日期：" prop="needSendTime">
                            <el-date-picker
                                style="width: 242px;"
                                type="date"
                                placeholder="选择日期"
                                v-model="form.needSendTime" clearable>
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="凭证：">
                            <ImageUpload v-model="form.voucherPhoto"
                                         @update:imageUploadCompleted="handleImageUpload"></ImageUpload>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input type="textarea" show-word-limit :maxlength="100" :rows="3"
                                      placeholder="填写备注信息" v-model="form.remarkInfo" clearable></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-main>

        <el-footer class="com-eTrip-footer" height="64px">
            <div class="buttons">
                <el-button type="primary" :loading="loading" @click="handleSubmit">提交转账申请</el-button>
            </div>
        </el-footer>
    </div>

</template>

<script>
import ImageUpload from '@/components/imageUpload/index'
import {addTransferAccountsApi} from "@/www/urls/transferAccounts";
import {paymentSubjectOptions} from '@/data/transferAccounts'
import CInput from '@/components/c-input/index'
import config from "@/config";

const createForm = () => ({
    transferAccountsPrice: undefined,//  金额
    accountName: '上海久柏易游信息科技有限公司',//  打款账号名称
    accountId: '',// 打款账号
    sendName: '',//  收款名称
    sendId: '',//  收款账号
    voucherPhoto: '',// 凭证
    remarkInfo: '',// 备注
    needSendTime: +new Date()//期望日期
})
export default {
    components: {
        ImageUpload,
        CInput
    },
    data() {
        return {
            loading: false,
            form: createForm(),
            paymentSubjectOptions: config.Debug ? paymentSubjectOptions :
                paymentSubjectOptions.slice(0, paymentSubjectOptions.length - 1),
            sendAccounts: [],
            rules: {
                accountId: [{required: true, message: '请输入支付主体账号'}],
                accountName: [{required: true, message: '请输入支付主体名称'}],
                sendName: [{required: true, message: '请输入收款单位的支付宝用户名'}],
                sendId: [{required: true, message: '请输入收款单位的支付宝账号'}],
                transferAccountsPrice: [{required: true, message: '请输入转账金额'}, {
                    validator: (rule, value, callback) => {
                        if (value <= 0) {
                            return callback('请输入转账金额')
                        }
                        return callback();
                    }, trigger: 'blur'
                }],
                needSendTime: [{required: true, message: '请选择期望支付日期'}],
            }
        }
    },
    mounted() {
        this.sendAccounts = (this.localStorage.getObject('send_accounts') || []).map((item) => ({
            ...item,
            value: item.sendName
        }));
    },
    methods: {
        async handleSubmit() {
            try {
                this.loading = true;
                await this.$refs.form.validate();
                await addTransferAccountsApi(this.form);
                this.$message.success('提交成功～')
                this.$router.back();
                const index = this.sendAccounts.findIndex(({sendId}) => sendId === this.form.sendId)
                if (index !== -1)
                    this.sendAccounts.splice(index, 1)
                this.sendAccounts.unshift({
                    sendId: this.form.sendId,
                    sendName: this.form.sendName,
                })
                this.localStorage.setObject('send_accounts', this.sendAccounts)
            } finally {
                this.loading = false;
            }
        },
        handlePrint() {

        },
        handleImageUpload(e) {
            this.form.voucherPhoto = e[0].imageUrl;
        },
        querySearch(queryString, cb) {
            cb(this.sendAccounts.filter(({sendName}) => sendName.includes(queryString)))
        },
        handleSendNameSelect(opt) {
            this.form.sendId = opt.sendId;
        }
    },
    watch: {
        'form.accountId'(val) {
            if (val === 'regixe7006@sandbox.com') {
                this.form.sendName = 'gsdcil8576'
                this.form.sendId = 'gsdcil8576@sandbox.com'
            }
        }
    }
}
</script>

<style scoped lang="scss">
.transfer-accounts-detail {
    .transfer-accounts-content {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 50px;

        .form {
            width: 900px;

            .icon-alipay {
                width: 52px;
                height: 20px;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
    }

    &::v-deep() {
        .el-alert--warning {
            background: #E6F7FF;
            border: 1px solid #BAE7FF;
            border-radius: 4px;
            height: 40px;
        }

        .image-cont {
            margin-left: 0;
        }

        .el-icon-warning {
            color: #1890FF;
        }

        .el-alert__title {
            color: rgba(0, 0, 0, .65);
            font-size: 14px;
        }
    }
}
</style>
