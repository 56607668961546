//支付主体
export const paymentSubjectOptions = [
    {
        label: 'app1@900etrip.com',
        value: 'app1@900etrip.com'
    },
    {
        label: 'app4@900etrip.com',
        value: 'app4@900etrip.com'
    },
    {
        label: 'app5@900etrip.com',
        value: 'app5@900etrip.com'
    },
    {
        label: 'app6@900etrip.com',
        value: 'app6@900etrip.com'
    },
    {
        label: '测试账号',
        value: 'regixe7006@sandbox.com'
    }
]

//收款单位
export const payeeOptions = []

//收款账号
export const collectionAccountOptions = [];

//状态
export const statusOptions = [
    {
        label: '待业务审核',
        value: 8
    },
    {
        label: '待财务审核',
        value: 1,
    },
    {
        label: '待转出',
        value: 7,
    },
    {
        label: '业务审核未通过',
        value: 9,
    },
    {
        label: '财务审核未通过',
        value: 3,
    },
    {
        label: '转账中',
        value: 6,
    },
    {
        label: '已转出',
        value: 2
    },
    {
        label: '转账异常',
        value: 5,
        color: '#FF4D4F'
    }
]

export const flowStatusOptions = [
    {
        label: '转账中',
        value: 0
    },
    {
        label: '成功',
        value: 1
    },
    {
        label: '失败',
        value: 2,
        color: '#FF4D4F'
    }
]
