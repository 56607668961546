<template>
    <div class="transfer-accounts-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div class="transfer-accounts-content" ref="printContent" id="printView">
                    <div>
                        <el-alert size="small"
                                  title="向支付宝账户转账流程：申请人提交申请 → 审核人审核通过 → 转出人操作转账"
                                  type="warning"
                                  :closable="false" show-icon>
                        </el-alert>
                        <el-descriptions class="mt15" :column="1" :colon="false"
                                         :label-style="labelStyle"
                                         :content-style="contentStyle">
                            <el-descriptions-item label="状态：">
                                <b>
                                    {{ form.status | formatDesc(statusOptions) }}
                                </b>
                                <el-popover
                                    v-if="[3,9].includes(form.status)"
                                    placement="top-start"
                                    width="200"
                                    trigger="hover">
                                    <p class="color-error">{{ form.refuseInfo }}</p>
                                    <i slot="reference" style="cursor: pointer"
                                       class="icon icon-not-pass color-error"></i>
                                </el-popover>
                                （申请人：{{
                                    form.craeteLoginName
                                }} {{
                                    form.reviewLoginName ? ' - 业务审核人：' + form.reviewLoginName : ''
                                }}{{
                                    form.transferAccountsLoginName ? ' - 财务审核人：' + form.transferAccountsLoginName : ''
                                }}{{ form.confirmLoginName ? ' - 转出人：' + form.confirmLoginName : '' }}）
                            </el-descriptions-item>
                            <el-descriptions-item label="申请时间：">
                                {{ form.createTime | dateCus }}
                            </el-descriptions-item>
                            <el-descriptions-item label="转出时间：" v-if="form.confirmTime">
                                {{ form.confirmTime | dateCus }}
                            </el-descriptions-item>
                            <el-descriptions-item label="转账流水信息：" v-if="[2,4,5,6].includes(form.status)"
                            >
                                <el-button type="primary" size="medium" plain
                                           @click="handleCheckFlow">
                                    查看流水信息表
                                </el-button>
                            </el-descriptions-item>
                            <el-descriptions-item label="支付主体：">
                                {{ form.accountId }}
                            </el-descriptions-item>
                            <el-descriptions-item label="支付主体名称：">
                                {{ form.accountName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="收款单位：">
                                <div class="alipay-box">
                                    <img class="icon-alipay" src="@/assets/images/alipay.png" alt="">
                                    {{ form.sendName }}
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="收款账号：">
                                <div class="alipay-box">
                                    <img class="icon-alipay" src="@/assets/images/alipay.png" alt="">
                                    {{ form.sendId }}
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="转账金额：">
                                {{ form.transferAccountsPrice | currency(1,'¥') }}
                                人民币{{ form.transferAccountsPrice | chineseCurrency }}
                            </el-descriptions-item>
                            <el-descriptions-item label="期望支付日期：">
                                {{ form.needSendTime | dateCus('yyyy-MM-dd') }}
                            </el-descriptions-item>
                            <el-descriptions-item label="凭证：">
                                <div style="width:104px;height:104px" v-if="form.voucherPhoto">
                                    <el-image
                                        fit="contain"
                                        style="max-width: 104px;max-height: 104px;"
                                        :src="form.voucherPhoto"
                                        :preview-src-list="[form.voucherPhoto]">
                                    </el-image>
                                </div>
                                <p v-else>无</p>
                            </el-descriptions-item>
                            <el-descriptions-item label="备注：">
                                {{ form.remarkInfo || '无' }}
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                    <div style="width:800px;page-break-before:always" v-if="showPrintView">
                        <FlowTable :info="form"></FlowTable>
                        <div style="height: 50px;background-color: red"></div>
                    </div>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="buttons">
                <el-button type="primary" style="width: 110px" plain :loading="loading" @click="handlePrint">
                    打印预览
                </el-button>
                <div class="rt" v-if="([1,7].includes(form.status)&&userInfo.roleName==='总部财务')||
                (form.status===8&&['总部超级管理员','总部运力管理员','总部管理员','总部操作员'].includes(userInfo.roleName))">
                    <el-popover
                        placement="top-start"
                        width="433"
                        v-model="refusePopover"
                        trigger="manual" v-if="[1,8].includes(form.status)">
                        <div class="popover-content">
                            <p class="mb15">请输入拒绝通过的原因：</p>
                            <el-form label-width="0" ref="refuseForm" :model="refuseForm" :rules="refuseRules">
                                <el-form-item prop="refuseInfo">
                                    <el-input type="textarea" v-model="refuseForm.refuseInfo" show-word-limit
                                              :maxlength="300"
                                              placeholder="如哪项内容填写错误，或哪项内容不符合要求等…"></el-input>
                                </el-form-item>
                            </el-form>
                            <div class="buttons" style="margin-top: 20px;display: flex;justify-content: flex-end">
                                <el-button type="primary" plain size="medium" style="width: 110px" :loading="loading"
                                           @click="refusePopover=false">
                                    取消
                                </el-button>
                                <el-button type="primary" size="medium" style="width: 110px" :loading="loading"
                                           @click="handleRefuse">
                                    确认
                                </el-button>
                            </div>
                        </div>
                        <el-button type="danger" slot="reference" style="width: 120px;margin-right: 20px"
                                   :loading="loading" plain
                                   @click="refusePopover=true">
                            拒绝
                        </el-button>
                    </el-popover>
                    <el-popover
                        placement="top-start"
                        title="确认通过审核"
                        width="387"
                        v-model="confirmVerifyPopover"
                        trigger="manual" v-if="[1,8].includes(form.status)">
                        <div class="popover-content">
                            <p>此操作不可逆，请确认内容已被仔细审阅，没有遗漏。</p>
                            <div class="buttons" style="margin-top: 20px;display: flex;justify-content: flex-end">
                                <el-button type="primary" plain size="medium" style="width: 110px" :loading="loading"
                                           @click="confirmVerifyPopover=false">
                                    取消
                                </el-button>
                                <el-button type="primary" size="medium" style="width: 110px" :loading="loading"
                                           @click="handleConfirmVerify">
                                    确认
                                </el-button>
                            </div>
                        </div>
                        <el-button slot="reference" type="primary" style="width: 120px"
                                   @click="confirmVerifyPopover=true">
                            通过
                        </el-button>
                    </el-popover>
                    <span v-if="form.status==7"
                          style="color: #1989FA;margin-right: 20px;font-size: 14px">根据以上内容向收款单位转账</span>
                    <el-popover
                        placement="top-start"
                        title="请输入转账安全密码"
                        width="387"
                        v-model="confirmPaymentPopover"
                        trigger="manual" v-if="form.status===7" @hide="handlePaymentPopoverHide">
                        <div class="popover-content">
                            <p class="mb10">请核对内容后谨慎操作，点击“确认”按钮即为转账</p>
                            <el-form ref="paymentFormRef" :model="paymentForm" :rules="paymentFormRules">
                                <el-form-item prop="pwd">
                                    <CodeInput ref="codeInputRef" type="password" key="password"
                                               @change="handlePasswordChange"></CodeInput>
                                </el-form-item>
                            </el-form>
                            <div class="buttons" style="margin-top: 20px;display: flex;justify-content: flex-end">
                                <el-button type="primary" plain size="medium" style="width: 110px" :loading="loading"
                                           @click="confirmPaymentPopover=false">
                                    取消
                                </el-button>
                                <el-button type="primary" size="medium" style="width: 110px" :loading="loading"
                                           @click="handleConfirmPayment">
                                    确认
                                </el-button>
                            </div>
                        </div>
                        <span slot="reference">
                              <el-button type="primary"
                                         :disabled="userInfo.loginuserId===form.transferAccountsLoginId"
                                         style="width: 120px"
                                         @click="handlePaymentPopoverShow">
                            转账
                        </el-button>
                        </span>
                    </el-popover>
                </div>
            </div>
        </el-footer>
    </div>
</template>
<script>
import {
    addTransferAccountsCashierAuditApi,
    addTransferAccountsCashierReviewApi,
    addTransferAccountsCashierReviewRefuseApi,
    addTransferAccountsFinanceAuditApi,
    addTransferAccountsFinanceRefuseApi,
    getTransferAccountsInfoByIdApi
} from "@/www/urls/transferAccounts";
import {statusOptions} from "@/data/transferAccounts";
import FlowTable from "@/www/pages/financeManage/transferAccounts/FlowTable";
import CodeInput from "@/components/code-input";

export default {
    components: {
        FlowTable,
        CodeInput
    },
    data() {
        return {
            statusOptions,
            loading: false,
            confirmVerifyPopover: false,
            confirmPaymentPopover: false,
            refusePopover: false,
            form: {},
            paymentForm: {
                pwd: ''
            },
            refuseForm: {
                refuseInfo: ''
            },
            refuseRules: {
                refuseInfo: [{required: true, message: '请输入拒绝理由'}]
            },
            labelStyle: {
                width: '130px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                fontWeight: 'normal',
                color: '#333',
            },
            contentStyle: {
                fontWeight: 'normal',
                color: '#333'
            },
            printObj: {
                id: 'printView',
                popTitle: '转账详情'
            },
            showPrintView: false,
            paymentFormRules: {
                pwd: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value || value.length !== 6) {
                                callback(new Error('请在此输入安全密码'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ]
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    mounted() {
        this.form = JSON.parse(decodeURIComponent(this.$route.query.info));
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.form = (await getTransferAccountsInfoByIdApi({
                    id: this.form.id
                })).data
            } finally {
                console.log(3333);
            }
        },
        //财务确定打款
        async handleConfirmPayment() {
            try {
                await this.$refs['paymentFormRef'].validate();
                this.loadingShow({
                    fullscreen: true,
                    text: '正在打款，请稍后...'
                });
                this.loading = true;
                const ret = await addTransferAccountsCashierAuditApi({
                    id: this.form.id,
                    payPwd: this.paymentForm.pwd
                });
                if (ret.success && ret.data) {
                    await this.getData();
                    this.$message.success('操作成功');
                    this.confirmPaymentPopover = false;
                } else {
                    this.$message.error(ret.errors[0].message);
                    this.$refs['codeInputRef'].setValue('');
                }
            } finally {
                this.loading = false;
                this.loadingHide();
            }
        },
        //业务初审和出纳复审通过
        async handleConfirmVerify() {
            try {
                this.loadingShow({fullscreen: true,});
                this.loading = true;
                const fn = this.form.status === 8 ? addTransferAccountsCashierReviewApi :
                    addTransferAccountsFinanceAuditApi;
                const ret = await fn({
                    id: this.form.id
                });
                if (ret.success && ret.data) {
                    await this.getData();
                    this.$message.success('操作成功');
                    this.confirmVerifyPopover = false
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false;
                this.loadingHide();
            }
        },
        //业务初审和出纳复审拒绝
        async handleRefuse() {
            try {
                this.loading = true;
                await this.$refs.refuseForm.validate();
                const fn = this.form.status === 8 ? addTransferAccountsCashierReviewRefuseApi :
                    addTransferAccountsFinanceRefuseApi;
                const ret = await fn({
                    id: this.form.id,
                    refuseInfo: this.refuseForm.refuseInfo
                });
                if (ret.success && ret.data) {
                    await this.getData();
                    this.refusePopover = false;
                    this.$message.success("操作成功");
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false;
            }
        },
        handlePrint() {
            this.$router.push('/transferAccountsPrint?info=' + this.$route.query.info)
        },
        handleImageUpload(e) {
            this.form.voucherPhoto = e[0].imageUrl;
        },
        handleCheckFlow() {
            this.$router.push('/transferAccountsFlow?info=' + this.$route.query.info)
        },
        handlePasswordChange(val) {
            this.paymentForm.pwd = val;
        },
        handlePaymentPopoverHide() {
            this.$refs['codeInputRef'].setValue('');
            this.$refs['paymentFormRef'].clearValidate()
        },
        handlePaymentPopoverShow() {
            this.confirmPaymentPopover = true;
            this.$refs['paymentFormRef'] && this.$refs['paymentFormRef'].clearValidate()
            setTimeout(() => {
                this.$refs['codeInputRef'].setFocus();
            }, 200)
        }
    }
}
</script>

<style scoped lang="scss">
.transfer-accounts-detail {
    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
    }
}

.transfer-accounts-content {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 50px;

    .alipay-box {
        display: flex;
        align-items: center;
        position: relative;

        .icon-alipay {
            width: 52px;
            height: 20px;
            margin-right: 20px;
        }

        &:after {
            content: ' ';
            position: absolute;
            left: 62px;
            width: 1px;
            height: 14px;
            background-color: #909399;
        }
    }

    &::v-deep() {
        .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
            padding-bottom: 25px;
        }

        .el-alert--warning {
            background: #E6F7FF;
            border: 1px solid #BAE7FF;
            border-radius: 4px;
            height: 40px;
        }

        .image-cont {
            margin-left: 0;
        }

        .el-icon-warning {
            color: #1890FF;
        }

        .el-alert__title {
            color: rgba(0, 0, 0, .65);
            font-size: 14px;
        }

        img {
            max-width: 104px;
            max-height: 104px;
        }
    }
}

</style>
