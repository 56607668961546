<template>
    <div class="transfer-accounts">
        <el-main class="com-eTrip-section section-has-footer">
            <comListSearch>
                <div slot="list-search-before">
                    <el-row>
                        <el-col :span="24">
                            <ApiRadio size="mini" button v-model="form.status" :options="getStatusOptions"></ApiRadio>
                        </el-col>
                    </el-row>
                    <el-row class="mt10">
                        <el-col :span="6">
                            <el-date-picker
                                size="small"
                                style="width: 95%"
                                v-model="form.createTimes"
                                type="daterange"
                                start-placeholder="申请日期起"
                                end-placeholder="申请日期止">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="6">
                            <el-date-picker
                                size="small"
                                style="width: 95%"
                                v-model="form.confirmTimes"
                                type="daterange"
                                start-placeholder="转出日期起"
                                end-placeholder="转出日期止">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="4">
                            <ApiSelect size="small" clearable style="width: 90%" :options="paymentSubjectOptions"
                                       placeholder="支付主体" v-model="form.accountId"></ApiSelect>
                        </el-col>
                        <el-col :span="4">
                            <el-autocomplete size="small" style="width: 90%;" v-model="form.sendName"
                                             :fetch-suggestions="querySearch1"
                                             placeholder="收款单位"
                                             clearable>
                            </el-autocomplete>
                        </el-col>
                        <el-col :span="4">
                            <el-autocomplete size="small" style="width: 90%;" v-model="form.sendId"
                                             :fetch-suggestions="querySearch2"
                                             placeholder="收款账号"
                                             clearable>
                            </el-autocomplete>
                        </el-col>
                    </el-row>
                </div>
                <div slot="list-search-after">
                    <el-row style="width: 250px;margin-left: -45px">
                        <el-col :span="12">
                            <el-button size="medium" style="width: 100%" type="primary" plain @click="handleAdd">发起转账申请
                            </el-button>
                        </el-col>
                        <el-col :span="12">
                            <el-button style="width: 80px;" size="small" type="primary" class="ml10"
                                       @click="handleExport">导出
                            </el-button>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 13px" type="flex" justify="space-between">
                        <el-col :span="12">
                            <el-button style="width: 80px" size="small" type="primary" plain :loading="loading"
                                       @click="reset">重置
                            </el-button>
                        </el-col>
                        <el-col :span="12" style="display: flex;justify-content: flex-end">
                            <el-button style="width: 80px;" size="small" type="primary" :loading="loading"
                                       @click="getData">搜索
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </comListSearch>
            <el-table :data="result.data" height="100%" border>
                <el-table-column label="状态" width="120" prop="status" align="center">
                    <template slot-scope="scope">
                        <span :style="{color:statusOptions.find(({value})=>value===scope.row.status).color}">
                            {{ scope.row.status | formatDesc(statusOptions) }}
                        </span>
                        <el-popover
                            v-if="[3,9].includes(scope.row.status)"
                            placement="top-start"
                            width="200"
                            trigger="hover">
                            <p class="color-error">{{ scope.row.refuseInfo }}</p>
                            <i slot="reference" style="cursor: pointer" class="icon icon-not-pass color-error"></i>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="期望转账日期" width="120" prop="needSendTime" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.needSendTime | dateCus('yyyy-MM-dd') }}
                    </template>
                </el-table-column>
                <el-table-column label="申请时间 - 转出时间" width="200" align="center">
                    <template slot-scope="scope">
                        <el-row>
                            <el-col :span="10">
                                {{ scope.row.createTime | dateCus }}
                            </el-col>
                            <el-col :span="4"
                                    style="display: flex;align-items: center;justify-content: center;height: 50px">-
                            </el-col>
                            <el-col :span="10">
                                {{ scope.row.confirmTime | dateCus }}
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column label="转账金额 ¥" prop="transferAccountsPrice" align="center" width="160">
                    <template slot-scope="scope">
                        {{ scope.row.transferAccountsPrice | currency }}
                    </template>
                </el-table-column>
                <el-table-column label="支付主体">
                    <template slot-scope="scope">
                        <p> {{ scope.row.accountName }}</p>
                        <p> {{ scope.row.accountId }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="收款单位和帐号">
                    <template slot-scope="scope">
                        <p> {{ scope.row.sendName }}</p>
                        <p> {{ scope.row.sendId }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="申请人-业务审核人-财务审核人-转出人">
                    <template slot-scope="scope">
                        {{
                            scope.row.craeteLoginName
                        }}{{
                            scope.row.reviewLoginName ? ' - ' + scope.row.reviewLoginName : ''
                        }}{{
                            scope.row.transferAccountsLoginName ? ' - ' + scope.row.transferAccountsLoginName : ''
                        }}{{ scope.row.confirmLoginName ? ' - ' + scope.row.confirmLoginName : '' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleCheck(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div style="display: flex;justify-content: flex-end;margin: 10px 0;width: 100%">
                <el-pagination background layout="prev, pager, next"
                               :page-count="form.pageIndex"
                               :page-size="form.pageSize"
                               :total="result.total"
                               @current-change="onPageChange"></el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<script>
import comListSearch from '@/www/components/comListSearch/index.vue'
import {collectionAccountOptions, payeeOptions, paymentSubjectOptions, statusOptions} from '@/data/transferAccounts'
import {getTransferAccountsInfoListApi} from "@/www/urls/transferAccounts";
import dayjs from "dayjs";


const createForm = () => ({
    pageSize: 10,
    pageIndex: 1,
    status: '全部',
    createTimes: [],
    confirmTimes: []
})
export default {
    name: "transferAccounts",
    components: {
        comListSearch
    },
    data() {
        return {
            paymentSubjectOptions,
            payeeOptions,
            collectionAccountOptions,
            statusOptions,
            form: createForm(),
            loading: false,
            result: {
                total: 0,
                data: []
            },
            sendAccounts: [],
        }
    },
    computed: {
        getStatusOptions() {
            return [{
                label: '全部'
            }, ...statusOptions]
        }
    },
    activated() {
        this.getData();
        this.sendAccounts = (this.localStorage.getObject('send_accounts') || [])
    },
    methods: {
        getForm() {
            return {
                ...this.form,
                status: this.form.status === '全部' ? undefined : statusOptions.find(({label}) => label === this.form.status).value,
                startCreateTime: this.form.createTimes.length === 2 ? dayjs(this.form.createTimes[0]).startOf('d').valueOf() : undefined,
                endCreateTime: this.form.createTimes.length === 2 ? dayjs(this.form.createTimes[1]).endOf('d').valueOf() : undefined,
                startConfirmTime: this.form.confirmTimes.length === 2 ? dayjs(this.form.confirmTimes[0]).startOf('d').valueOf() : undefined,
                endConfirmTime: this.form.confirmTimes.length === 2 ? dayjs(this.form.confirmTimes[1]).endOf('d').valueOf() : undefined,
                sendId: this.form.sendId ? this.form.sendId : undefined,
                sendName: this.form.sendName ? this.form.sendName : undefined,
                accountId: this.form.accountId ? this.form.accountId : undefined,
            }
        },
        async getData() {
            try {
                this.loading = true;
                this.result = (await getTransferAccountsInfoListApi(this.getForm())).data;
            } finally {
                this.loading = false;
            }
        },
        reset() {
            this.form = createForm();
            this.getData();
        },
        handleAdd() {
            this.$router.push('/transferAccountsAdd')
        },
        handleCheck(item) {
            this.$router.push('/transferAccountsDetail?info=' + encodeURIComponent(JSON.stringify(item)))
        },
        onPageChange(page) {
            this.form.pageIndex = page;
            this.getData();
        },
        querySearch1(queryString, cb) {
            cb(this.sendAccounts.filter(({sendName}) => sendName.includes(queryString)).map((item) => ({
                ...item,
                value: item.sendName
            })))
        },
        querySearch2(queryString, cb) {
            console.log(this.sendAccounts);
            cb(this.sendAccounts.filter(({sendId}) => sendId.includes(queryString)).map((item) => ({
                ...item,
                value: item.sendId
            })))
        },
        async handleExport() {
            this.loadingShow();
            const ret = await this.http(`/galaxyTransferAccounts/exportTransferAccountsInfo`, this.getForm(), 'POST', true, 'blob')
            this.loadingHide();
            let a = document.createElement('a');
            a.download = '转账记录导出_' + dayjs().format('YYYY-MM-DD HH:mm:ss') + '.xls';
            a.href = window.URL.createObjectURL(ret);
            a.click();
        }
    }
}
</script>

<style scoped lang="scss">
.transfer-accounts {
    &::v-deep() {
        .list-search-after {
            width: 170px;
        }
    }
}
</style>
