<template>
    <el-dialog :visible.sync="dialogVisible" width="500px" @close="clear">
        <el-form ref="form" :model="form" :rules="rules">
            <!--设置密码-->
            <div class="section" v-show="step===1">
                <h3 class="title">设置转账安全密码</h3>
                <p class="tips">请在此输入安全密码，密码为6位数字组成</p>
                <el-form-item prop="password">
                    <CodeInput ref="codeInputRef1" type="password" key="password"
                               @change="handleInput1Change"></CodeInput>
                </el-form-item>
            </div>
            <!--确认密码-->
            <div class="section" v-show="step===2">
                <h3 class="title">请再次输入上一步填写的密码数字</h3>
                <p class="tips">请与上一次输入的数字保持一致</p>
                <el-form-item prop="password1">
                    <CodeInput ref="codeInputRef2" type="password" key="password1"
                               @change="handleInput2Change"></CodeInput>
                </el-form-item>
            </div>
            <!--密码设置成功-->
            <div class="section" v-show="step===3">
                <h3 class="title">安全密码设置成功</h3>
                <p class="tips">可以开始使用了！请妥善保管密码</p>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" plain v-if="step===2" @click="step= step-1">上一步</el-button>
                <el-button type="primary" :loading="loading" @click="handleSubmit">
                    {{ step === 3 ? '好的' : '下一步' }}
                </el-button>
  </span>
    </el-dialog>
</template>

<script>
import CodeInput from '@/components/code-input/index'
import {addUserPayPwdApi} from "@/www/urls/user";

export default {
    name: "SetPasswordDialog",
    components: {
        CodeInput
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            step: 1,//1设置密码第一步  2设置密码第二步确认密码 3密码设置成功
            form: {
                password: '',
                password1: '',
            },
            rules: {
                password: [
                    {
                        validator: (rule, value, callback) => {
                            if (!value || value.length !== 6) {
                                callback(new Error('请在此输入安全密码，密码为6位数字组成'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'change'
                    }
                ],
                password1: [{
                    validator: (rule, value, callback) => {
                        if (!value || value !== this.form.password) {
                            callback(new Error('请与上一次输入的数字保持一致'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }],
            }
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
            this.step = 1;
            this.setFocus();
        },
        setFocus() {
            setTimeout(() => {
                this.$refs[`codeInputRef${this.step}`] && this.$refs[`codeInputRef${this.step}`].setFocus();
            }, 200)
        },
        clear() {
            this.$refs['codeInputRef1'].setValue('');
            this.$refs['codeInputRef2'].setValue('');
        },
        handleInput1Change(val) {
            this.form.password = val;
        },
        handleInput2Change(val) {
            this.form.password1 = val;
        },
        async handleSubmit() {
            try {
                this.loading = true;
                if (this.step === 1) {
                    await this.$refs['form'].validateField('password');
                    this.step = 2;
                } else if (this.step === 3) {
                    this.dialogVisible = false;
                } else if (this.step === 2) {
                    await this.$refs['form'].validateField('password1');
                    const ret = await addUserPayPwdApi({
                        pwd: this.form.password
                    })
                    if (ret.success && ret.data) {
                        this.step = 3;
                        this.$emit('onRefresh')
                    } else this.$message.error(ret.errors[0].message)
                }
            } finally {
                this.loading = false;
                this.$refs['form'].clearValidate();
                this.setFocus();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.section {
    .title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .tips {
        margin-bottom: 10px;
    }

    .buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 36px;
    }
}
</style>
