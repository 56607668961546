<style lang="scss" scoped>
    .product-list {
        .product-list-inline {
            .el-form-item {
                width: 135px;
            }
        }

        .product-check-list {
            margin-top: 5px;

            .check-list-title {
                font-size: 14px;
                margin-right: 20px;
            }

            .check-list-group {
                display: inline-block;
            }
        }

    }
</style>
<style lang="scss">

    .product-list-table {
        .operation-btn {
            .el-button {
                padding: 0;
                margin: 5px;
                margin-left: 0;
                float: left;
            }
        }
    }

    .common-list-search .list-search-before {
        .label-pub-w {
            &.el-form-item.el-form-item--small {
                width: auto;
                /*min-width: 320px;*/
            }

            .el-form-item__content {
                width: auto;
            }
        }
    }
    .table-info{
        .el-table_1_column_5{
            padding: 0;
        }
    }
    .table-second{
        .el-table td  {
            padding: 12px 0!important;
        }
        .el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell{
            padding-left: 0!important;
        }
        .el-table .cell, .el-table th div{
            padding-right: 0!important;
        }
    }
</style>
<template>
    <div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="product-list">
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form :inline="true" :model="form">
                            <el-form-item>
                                <!--<el-input placeholder="旅行社名称" v-model="form.companyName"></el-input>-->
                                <el-autocomplete style="width: 200px"
                                        v-model="form.companyName"
                                        :fetch-suggestions="querySearchAsync"
                                        placeholder="旅行社名称" :trigger-on-focus="false"
                                        @select="handleSelect"
                                >
                                    <template slot-scope="{ item }">
                                        <span>{{item.merchantName}}</span>
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="机构ID" v-model="form.merchantId"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after" class="list-search-after_sec">
                        <el-button @click="resetClick()">重置</el-button>
                        <el-button type="primary" @click="searchClick()">搜索</el-button>
                    </div>
                </com-list-search>
                <div class="product-list-table el-table_border_none" style="margin-top: 10x">
                    <el-table center
                            class="table-info"
                            border
                            :data="agencyList"
                            v-loading="listLoading"
                            style="width: 100%"
                            :span-method="arraySpanMethod"
                    >
                        <el-table-column type="index" :index="indexMethod" label="序号" width="110"></el-table-column>
                        <el-table-column prop="merchantId" width="120" label="机构ID"></el-table-column>
                        <el-table-column prop="merchantName" label="一级用户名称" width="180"></el-table-column>
                        <el-table-column prop="accountBalance" label="余额" width="120"></el-table-column>
                        <el-table-column prop="accountBalance" label="机构ID" width="120">
                            <template slot-scope="scope">
                                <div style="max-height: 250px;overflow: scroll;">
                                    <el-table :show-header="false"
                                              class="table-second"
                                              border :tree-props="{ hasChildren: 'hasChildren', children: 'children' }"
                                              :data="scope.row.secondAccountRspDtos"
                                              style="width: 100%"
                                    >
                                        <el-table-column prop="merchantId" width="120"></el-table-column>
                                        <el-table-column prop="merchantName" min-width="80"></el-table-column>
                                        <el-table-column min-width="80">
                                            <template slot-scope="scope">
                                                <div>
                                                    <span>{{scope.row.isFinancialAutonomy==1?'财务独立':'非财务独立'}}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="accountBalance" min-width="80"></el-table-column>
                                        <el-table-column width="150">
                                            <template slot-scope="scope">
                                                <div class="operation-btn clear">
                                                    <el-button type="text" :disabled="scope.row.isFinancialAutonomy===0"
                                                               @click="lookClick(scope.row)">查看</el-button>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>

                            </template>
                        </el-table-column>
                        <el-table-column label="二级用户名称" min-width="80"></el-table-column>
                        <el-table-column label="财务独立状态" min-width="80"></el-table-column>
                        <el-table-column label="余额" min-width="80"></el-table-column>
                        <el-table-column label="操作" width="150"></el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                            @current-change="pageChange"
                            :current-page.sync="pageData.currentPage"
                            :page-size="pageData.pageSize"
                            layout="total, prev, pager, next"
                            :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
        <el-dialog :visible.sync="dialogTableVisible">
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
    import comListSearch from "../../../components/comListSearch/index.vue";
    import SectionContent from "@/components/sectionContent/index.vue";

    export default {
        //定义模版数据
        name: "storeBalance",
        data() {
            return {
                form: {},
                pageData: {
                    // 列表分页
                    pageSize: 10, // 每页显示条目个数
                    totalPage: 1, // 总条目个数
                    currentPage: 1 // 当前页数
                },
                agencyList: [],
                listLoading: false,
                dialogTableVisible: false,
                list: []
            };
        },
        components: {
            SectionContent,
            comListSearch
        },
        //计算属性
        computed: {
        },
        //主件被加载完成
        mounted: function () {
            this.searchClick();
            // this.getAgency();
        },
        //定义事件方法
        methods: {
            resetClick() {
                // 重置
                this.form = {
                };
                this.searchClick();
            },
            pageChange(val) {
                this.pageData.currentPage = val;
                this.searchClick();
            },
            async searchClick() {
                let form = {};
                if(this.form.merchantId)form.merchantId = this.form.merchantId;
                if(this.form.merchantIdByName)form.merchantIdByName = this.form.merchantIdByName;
                form.pageIndex = this.pageData.currentPage;
                form.pageSize = this.pageData.pageSize;
                let res = await this.http('/galaxyAccountAuditInfo/listMerchantChargeAccountList', form, 'POST');
                console.log(res);
                if(res.success){
                    this.agencyList = res.data.data;
                    this.pageData.totalPage = res.data.total;
                }
            },
            indexMethod(index) {
                return (index+1)+ (this.pageData.currentPage-1)*10;
            },
            arraySpanMethod({  columnIndex }){
                if(columnIndex>3)
                return [1,5]
            },
            lookClick(data){
                this.$router.push({
                    name: "rechargeDetail",
                    params: {id: data.id}
                })
            },
            async getAgency(){
                const res = await this.http('/galaxyMerchant/listGalaxyMerchantInfoByName', '南航', 'POST')
                console.log(res);
                if(res.success){
                    this.list = res.data;
                }
            },
            async querySearchAsync(queryString, cb){
                let list = [];
                const res = await this.http('/galaxyMerchant/listGalaxyMerchantInfoByName', queryString, 'POST');
                if(res.success){
                    list = res.data;
                    let results = queryString ? list.filter(this.createFilter(queryString)) : list;
                    // 调用 callback 返回建议列表的数据
                    cb(results);
                }
            },
            createFilter(queryString) {
                return (state) => {
                    return (state.merchantName.indexOf(queryString) != -1);
                };
            },
            handleSelect(val){
                // console.log(val, '12312312312');
                this.form.companyName = val.merchantName;
                this.form.merchantIdByName = val.merchantId;
            }
        },
        //监听模版变量
        watch: {}
    };
</script>

