<template>
    <div class="flow-table">
        <h3 class="title">流水信息表</h3>
        <ul class="amount-to" v-if="getData.payTransferAccountsDetailDtoList">
            <li>
                <p>合计转出：{{ getData.payTransferAccountsDetailDtoList.length }}笔</p>
                <p>合计金额：{{
                        getData.payTransferAccountsDetailDtoList.reduce((a, b) => a + b.price, 0) |currency
                    }}
                </p>
            </li>
            <li>
                <p>
                    转出成功：{{
                        getData.payTransferAccountsDetailDtoList.filter(({status}) => status === 1).length
                    }}笔</p>
                <p>金额：{{
                        getData.payTransferAccountsDetailDtoList.filter(({status}) => status === 1).reduce((a, b) => a + b.price, 0) |currency
                    }} </p>
            </li>
            <li>
                <p>
                    转出失败：{{
                        getData.payTransferAccountsDetailDtoList.filter(({status}) => status === 2).length
                    }}笔</p>
                <p>金额：{{
                        getData.payTransferAccountsDetailDtoList.filter(({status}) => status ===2).reduce((a, b) => a + b.price, 0) |currency
                    }} </p>
            </li>
        </ul>
        <el-table :height="height" :data="[...(getData.payTransferAccountsDetailDtoList||[]),{}]"
                  :row-class-name="tableRowClassName">
            <el-table-column label="收款单位/帐号">
                <template slot-scope="scope">
                    <p>{{ getData.sendId }}</p>
                    <p>{{ getData.sendName }}</p>
                    <p style="font-size:12px;color:#FF3B30" v-if="scope.row.errorInfo">{{
                            scope.row.errorInfo
                        }}</p>
                </template>
            </el-table-column>
            <el-table-column label="900游转账流水号" prop="id"></el-table-column>
            <el-table-column label="支付宝资金流水号" prop="aliPayId"></el-table-column>
            <el-table-column label="金额 ¥" width="100" align="center">
                <template slot-scope="scope">
                    {{ scope.row.price | currency }}
                </template>
            </el-table-column>
            <el-table-column label="状态" width="100" align="center">
                <template slot-scope="scope">
                            <span :style="{color:scope.row.status === 2?'#FF3B30':'#333'}">
                                 {{ scope.row.status  | formatDesc(flowStatusOptions) }}
                            </span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>

import {flowStatusOptions} from "@/data/transferAccounts";

export default {
    name: "FlowTable",
    data() {
        return {
            flowStatusOptions
        }
    },
    props: {
        info: {
            type: Object,
            default: () => ({
                payTransferAccountsDetailDtoList: []
            })
        },
        height: {
            type: String,
            default: 'auto'
        }
    },
    computed: {
        getData() {
            return this.info
        }
    },
    methods: {
        tableRowClassName({row}) {
            if (!row.id)
                return 'null-row'
            return row.status !== 1 ? 'error-row' : ''
        }
    }
}
</script>

<style scoped lang="scss">
.flow-table {
    .title {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin: 30px 0;
    }

    .amount-to {
        display: flex;
        background-color: #F6F6F6;

        li {
            flex: 1;
            font-weight: 500;
            padding: 20px;
            line-height: 1.5em;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:not(:last-child):after {
                content: ' ';
                position: absolute;
                width: 1px;
                height: 30.5px;
                background-color: #DDDDDD;
                right: 0;
            }
        }
    }

    &::v-deep() {
        .el-table__row.error-row {
            background-color: #FFE5E6;
        }

        .el-table__row.null-row {
            opacity: 0;
        }

        .el-table__row {
            min-height: 71px;
        }
    }
}
</style>
