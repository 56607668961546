<template>
    <div class="transfer-accounts-flow">
        <el-main class="com-eTrip-section">
            <div class="eTrip-section-cont">
                <FlowTable :info="form" height="62vh"></FlowTable>
            </div>
        </el-main>
    </div>
</template>

<script>
import FlowTable from "@/www/pages/financeManage/transferAccounts/FlowTable";
import {getTransferAccountsInfoByIdApi} from "@/www/urls/transferAccounts";

export default {
    components: {
        FlowTable
    },
    data() {
        return {
            form: {
                payTransferAccountsDetailDtoList: []
            }
        }
    },
    mounted() {
        this.form = JSON.parse(decodeURIComponent(this.$route.query.info))
        console.log(this.form);
        this.getData();
    },
    methods: {
        async getData() {
            try {
                this.form = (await getTransferAccountsInfoByIdApi({
                    id: this.form.id
                })).data
            } finally {
                console.log(3333);
            }
        },
    }
}
</script>

<style scoped lang="scss">
.transfer-accounts-flow {
    .eTrip-section-cont {
        background-color: #fff;
        padding: 0 10px;
    }
}
</style>
